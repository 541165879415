import React from 'react';
import BodyHP from '../componets/Bodyhp';
import Layout from '../componets/layout';
import Popup from '../componets/popup-tarifaSocial';

const HomePage = () => {
    return (
        <div>
            <Popup />
            <Layout
                title='Cooperativa Eléctrica de Rivadavia - Inicio'
                description='Somos la Cooperativa Eléctrica de Rivadavia, dedicada a mejorar la calidad de vida de nuestros asociados a través de servicios eléctricos y valores cooperativos de excelencia.'
                keywords='Cooperativa Eléctrica, Cooperativa Electrica Rivadavia, Cooperativa Electrica Rivadavia Mendoza, CEAPR Rivadavia, Electricidad Rivadavia Mendoza, Electricidad, Asociados CEAPR'
            ></Layout>
            <BodyHP />
        </div>
    );
};
export default HomePage;
