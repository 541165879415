import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import DownloadFileButton from './downloadCuadroTarifario';
const AccordionElement = () => {
    return (
        <div className='contenedor-accordionelement'>
            <Accordion defaultActiveKey='0'>
                <Accordion.Item eventKey='1'>
                    <Accordion.Header>
                        {'>>'}Subsidio por fallecimiento
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Información sobre el subsidio por fallecimiento de
                            un asociado
                        </p>
                        <br />
                        <ul>
                            <li>
                                Adhesión voluntaria de los Socios de la
                                Cooperativa.
                            </li>
                            <li>
                                Beneficiarios: Titular y Grupo Familiar
                                primario.
                            </li>
                            <li>Autogestionado por la Cooperativa.</li>
                            <li>Atención personalizada.</li>
                            <li>Mínima cuota de integración.</li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion defaultActiveKey='0'>
                <Accordion.Item eventKey='1'>
                    <Accordion.Header>
                        {'>>'}Cambio de Titularidad
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            El trámite debe ser realizado por el titular del
                            suministro o apoderado (con poder ante escribano
                            público), presentando la siguiente documentación:
                        </p>{' '}
                        <br />
                        <br />
                        <ul>
                            <li>
                                Escritura (Original y fotocopia) o documentación
                                que acredite la posesión o tenencia legal del
                                inmueble
                            </li>
                            <li> Documento de identidad: original y copia.</li>
                            <li>
                                No poseer deuda en concepto de energía eléctrica
                                ante La Cooperativa.
                            </li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion defaultActiveKey='0'>
                <Accordion.Item eventKey='1'>
                    <Accordion.Header>{'>>'}Nueva conexión</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Para la Habilitación / Aumento de Potencia /
                            Recolocación o Cambio de Sitio de un suministro, el
                            titular deberá presentar:
                        </p>
                        <br />
                        <br />
                        <ul>
                            <li>
                                Permiso Municipal de conexión expedido por la
                                Municipalidad de Rivadavia.
                            </li>
                            <li>
                                Escritura de la propiedad (original y fotocopia)
                                o documentación que acredite la posesión o
                                tenencia legal del inmueble.
                            </li>
                            <li>Documento de identidad (original y copia)</li>
                            <li>
                                No poseer deuda en concepto de energía
                                eléctrica.
                            </li>
                            <li>Firmar la Solicitud de Suministro.</li>
                            <li>Abonar el cargo por conexión.</li>
                            <li>
                                En caso de solicitar la conexión para obra, el
                                responsable Técnico de la misma deberá firmar
                                también la solicitud de suministro.
                            </li>
                            <li>
                                En caso de corresponder, abonar el depósito en
                                garantía de acuerdo a lo establecido en el
                                Reglamento de Suministro.
                            </li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion defaultActiveKey='0'>
                <Accordion.Item eventKey='1'>
                    <Accordion.Header>{'>>'}Tarifa social</Accordion.Header>
                    <Accordion.Body>
                        <p>¿QUE ES LA TARIFA SOCIAL?</p>
                        <br />
                        <p>
                            Es un beneficio especial en la tarifa de energía
                            eléctrica en su componente de abastecimiento. A
                            partir de enero de 2019, dicho beneficio económico
                            fue asumido por el Gobierno Provincial.
                        </p>
                        <br />
                        <p>1. ¿Quiénes acceden a la Tarifa Social?</p>
                        <ul>
                            <br />
                            <li>
                                Ser jubilado o pensionado o trabajador en
                                relación de dependencia que perciba una
                                remuneración bruta menor o igual a DOS (2)
                                Salarios Mínimos Vitales y Móviles.
                            </li>
                            <li>
                                Ser trabajador “monotributista” inscripto en una
                                categoría cuyo ingreso anual mensualizado no
                                supere en dos (2) veces el Salario Mínimo Vital
                                y Móvil.
                            </li>
                            <li>
                                Ser beneficiario de una Pensión no Contributivas
                                y percibir ingresos mensuales brutos no
                                superiores a dos (2) veces el Salario Mínimo
                                Vital y Móvil.
                            </li>
                            <li>Ser titular de programas sociales.</li>
                            <li>
                                Estar inscripto en el Régimen de Monotributo
                                Social.
                            </li>
                            <li>
                                Estar incorporado en el Régimen Especial de
                                Seguridad Social para empleados del Servicio
                                Doméstico (26.844).
                            </li>
                            <li>Estar percibiendo el seguro de desempleo.</li>
                            <li>
                                Ser titular de una Pensión Vitalicia a Veteranos
                                de Guerra del Atlántico Sur.
                            </li>
                            <li>
                                Contar con certificado de discapacidad expedido
                                por autoridad competente.
                            </li>
                            <li>
                                Tener el titular o uno de sus convivientes una
                                enfermedad cuyo tratamiento implique
                                electrodependencia.
                            </li>
                        </ul>
                        <br />
                        <p>2. ¿Quiénes no acceden a la Tarifa Social?</p>
                        <br />
                        <ul>
                            <li>
                                Registro de propiedad Inmueble – quedarán
                                excluidos aquellos titulares de más de uno.
                            </li>
                            <li>
                                Padrón de Automotores – quedarán excluidos
                                aquellos cuyos modelos tengan hasta DIEZ (10)
                                años de antigüedad. Este criterio no aplica a
                                quienes posean certificado de discapacidad o
                                electrodependencia.
                            </li>
                            <li>
                                Embarcaciones de lujo – quedarán excluidos
                                quienes posean aeronaves o embarcaciones de
                                lujo.
                            </li>
                        </ul>
                        <br />
                        <p>
                            3. ¿Cómo me puedo dar de alta para ser beneficiario
                            de la Tarifa Social?
                        </p>
                        <br />
                        <p>
                            Recurriendo a su Distribuidora, consultando en el
                            Ente Provincial Eléctrico al Teléfono 148 opción 9 o
                            desde la aplicación EPRE USUARIOS.
                        </p>
                        <br />
                        <br />
                        <div style={{ textAlign: 'center' }}>
                            <p>
                                Consulte si Ud. es beneficiario de la Tarifa
                                Social{' '}
                                <a
                                    href='https://epremendoza.gob.ar/tarifa-social/'
                                    className='mb-5'
                                    target='_blank'
                                >
                                    aquí.
                                </a>{' '}
                            </p>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion defaultActiveKey='0'>
                <Accordion.Item eventKey='1'>
                    <Accordion.Header>
                        {'>>'}Electro -<br /> dependientes
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>¿Qué es la Electrodependencia?</p>
                        <br />
                        <p>
                            Electrodependencia es una condición de ciertas
                            personas, que por una deficiencia en su salud
                            requieren de la utilización de un dispositivo
                            eléctrico, con suministro constante y en niveles de
                            tensión adecuados. Su supervivencia depende del
                            correcto funcionamiento de los mismos.
                        </p>
                        <br />
                        <p>
                            ¿Cuál es el beneficio en la tarifa de energía
                            eléctrica?
                        </p>
                        <br />
                        <p>
                            Para quienes obtengan la condición de
                            electrodependiencia, la tarifa de energía eléctrica
                            está subsidiada por el Estado Provincial y Nacional
                            en su totalidad.
                        </p>
                        <br />
                        <p>
                            Formularios de INSCRIPCION / RENOVACION / CAMBIO DE
                            DOMILICIO DE ELECTRODEPENDIENTES, consideraciones a
                            tener en cuenta al LLENAR los Formularios e
                            Instructivo electrodependientes.
                        </p>
                        <p>
                            Descárgalos{' '}
                            <a
                                href='https://www.epremendoza.gob.ar/electrodependencia/'
                                className='mb-5'
                                target='_blank'
                            >
                                haciendo clic aquí.
                            </a>
                        </p>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            {/* <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="1">
          <Accordion.Header>{">>"}Factura Vencida</Accordion.Header>
          <Accordion.Body></Accordion.Body>
        </Accordion.Item>
      </Accordion> */}
            <Accordion defaultActiveKey='0'>
                <Accordion.Item eventKey='1'>
                    <Accordion.Header>{'>>'}Alumbrado público</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Recordá que los reclamos de alumbrado público se
                            realizán en la Municipalidad de Rivadavia.
                        </p>
                        <br />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            {/* <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="1">
          <Accordion.Header>{">>"}Actualización de datos</Accordion.Header>
          <Accordion.Body></Accordion.Body>
        </Accordion.Item>
      </Accordion> */}
            {/* <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="1">
          <Accordion.Header>{">>"}Energía destribuida</Accordion.Header>
          <Accordion.Body></Accordion.Body>
        </Accordion.Item>
      </Accordion> */}
            <Accordion defaultActiveKey='0'>
                <Accordion.Item eventKey='1'>
                    <Accordion.Header>{'>>'}Cuadro Tarifario</Accordion.Header>
                    <Accordion.Body style={{ textAlign: 'center' }}>
                        <p>
                            Descargue los diferentes cuadros tarifarios haciendo
                            clic en el botón:
                        </p>
                        <br />
                        <DownloadFileButton />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            {/* <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="1">
          <Accordion.Header>{">>"}Resoluciones EPRE</Accordion.Header>
          <Accordion.Body></Accordion.Body>
        </Accordion.Item>
      </Accordion> */}
            <Accordion defaultActiveKey='0'>
                <Accordion.Item eventKey='1'>
                    <Accordion.Header>
                        {'>>'}Horarios de Atención
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>De Lunes a Viernes de 7.30 a 14 horas.</p>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
};
export default AccordionElement;
