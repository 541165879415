import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import lamparaoff from '../images/lampara-off.png';
import lamparaon from '../images/lampara-on.png';
import btnEE1 from '../images/1btn-ee.png';
import btnEE2 from '../images/2btn-ee.png';
import btnEE3 from '../images/3btn-ee.png';
import btnEE4 from '../images/4btn-ee.png';
import btnEE5 from '../images/5btn-ee.png';
import Layout from '../componets/layout';

const EficienciaEnergetica = () => {
    const [ref, inView] = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });

    const [lamparaImg, setLamparaImg] = useState(lamparaoff);

    const handleMouseEnter = () => {
        setLamparaImg(lamparaon);
    };

    const handleMouseLeave = () => {
        setLamparaImg(lamparaoff);
    };

    return (
        <div
            id='eficiencia-energetica'
            className='eficiencia-energetica mt-5'
            ref={ref}
        >
            <Layout
                title='Cooperativa Eléctrica de Rivadavia - Eficiencia Enegetica'
                description='Nos impulsan los valores cooperativos, trabajando para mejorar la calidad de vida en Rivadavia a través de servicios eléctricos, innovación y desarrollo comunitario.'
                keywords='Cooperativa Eléctrica, Cooperativa Electrica Rivadavia, Cooperativa Electrica Rivadavia Mendoza, CEAPR Rivadavia'
            ></Layout>
            <div
                className='lampTitle'
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{ display: 'flex', flexDirection: 'column' }}
            >
                <div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flex: 1,
                        }}
                    >
                        <h1
                            style={{
                                fontSize: '60px',
                                marginLeft: '150px',
                                marginTop: '100px',
                            }}
                        >
                            <b>Eficiencia Energética</b>
                            <p
                                style={{
                                    fontSize: '25px',
                                    color: 'black',
                                    marginTop: '10px',
                                    marginLeft: '40px',
                                }}
                            >
                                La eficiencia energética implica usar bien la
                                energía, porque significa ahorrar sin perder en
                                calidad de vida o en calidad de producción. Te
                                dejamos algunos consejos para que tu casa y
                                oficina sean más eficientes:
                            </p>
                        </h1>

                        <img
                            src={lamparaImg}
                            alt='lampara'
                            style={{
                                width: '100%',
                                height: '100%',
                                maxWidth: '500px',
                                marginRight: '250px',
                                marginBottom: '50px',
                            }}
                        />
                    </div>
                    <div>
                        <ul
                            style={{
                                listStyle: 'none',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '30px',
                                marginBottom: '100px',
                            }}
                        >
                            <li
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '100px',
                                }}
                            >
                                <img
                                    src={btnEE1}
                                    style={{
                                        width: '70px',
                                        marginRight: '30px',
                                    }}
                                />
                                <div>
                                    <b>Vigila los Aparatos en Standby</b>
                                    <p>
                                        Para evitar este consumo silencioso
                                        basta con apagar totalmente y
                                        desenchufar los dispositivos que siguen
                                        consumiendo de forma innecesaria, como
                                        computadoras, televisores o cargadores,
                                        entre otros.
                                    </p>
                                </div>
                            </li>
                            <li
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '100px',
                                }}
                            >
                                <img
                                    src={btnEE2}
                                    style={{
                                        width: '70px',
                                        marginRight: '30px',
                                    }}
                                />
                                <div>
                                    <b>Aislar la vivienda</b>
                                    <p>
                                        Una solución para aislar ventanas son
                                        por los burletes. Unas bandas de
                                        silicona, u otros materiales, que se
                                        colocan en los bordes de la estructura
                                        de la ventana, sellándola
                                        herméticamente.
                                    </p>
                                </div>
                            </li>
                            <li
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '100px',
                                }}
                            >
                                <img
                                    src={btnEE3}
                                    style={{
                                        width: '70px',
                                        marginRight: '30px',
                                    }}
                                />
                                <div>
                                    <b>Reemplazar los focos por lámparas LED</b>
                                    <p>
                                        Su bajo consumo permite un importante
                                        ahorro energético. La eficiencia de la
                                        luminaria está basada en su amplia vida
                                        útil frente a la tradicional y su bajo
                                        consumo energético.
                                    </p>
                                </div>
                            </li>
                            <li
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '100px',
                                }}
                            >
                                <img
                                    src={btnEE4}
                                    style={{
                                        width: '70px',
                                        marginRight: '30px',
                                    }}
                                />
                                <div>
                                    <b>Apagar las luces</b>
                                    <p>
                                        Su bajo consumo permite un importante
                                        ahorro energético. La eficiencia de la
                                        luminaria está basada en su amplia vida
                                        útil frente a la tradicional y su bajo
                                        consumo energético.
                                    </p>
                                </div>
                            </li>
                            <li
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '100px',
                                }}
                            >
                                <img
                                    src={btnEE5}
                                    style={{
                                        width: '70px',
                                        marginRight: '30px',
                                    }}
                                />
                                <div>
                                    <b>Mantener en buen estado los aparatos</b>
                                    <p>
                                        Limpiar los filtros o la suciedad
                                        incrustada de los equipos contribuye al
                                        ahorro energético.
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EficienciaEnergetica;
