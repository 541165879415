import React from 'react';
import BannerTitle from '../componets/bannertitle';
import FollowUs from '../componets/follow';
import Layout from '../componets/layout';

const Nosotros = () => {
    return (
        <div className='contenedor-nosotros'>
            <Layout
                title='Cooperativa Eléctrica de Rivadavia - Nosotros'
                description='Descubre cómo la Cooperativa Eléctrica de Rivadavia ha sido un faro de excelencia en el suministro de electricidad y en la promoción de los valores cooperativos en Rivadavia durante más de un siglo.'
                keywords='Cooperativa Eléctrica, Cooperativa Electrica Rivadavia, Cooperativa Electrica Rivadavia Mendoza, CEAPR Rivadavia, Electricidad Rivadavia Mendoza, Electricidad, Asociados CEAPR'
            ></Layout>
            <BannerTitle
                titulo='INSTITUCION'
                direccionimagen='url(/banner2.png)'
                direccionimagenMobile={'url(/banner2.png)'}
            />
            <div className='nosotros-content'>
                <h2>Autoridades:</h2>
                <div className='list'>
                    <h3>Honorable Consejo de Administración 2017/2018</h3>
                    <ul>
                        <li>
                            <h4>Presidente</h4>
                            <p>Carlos Javier Fornasari</p>
                        </li>
                        <li>
                            <h4>Vicepresidente</h4>
                            <p>Lorentina Lomellini</p>
                        </li>
                        <li>
                            <h4>Tesorero</h4>
                            <p>Juan Gerardo Del Río</p>
                        </li>
                        <li>
                            <h4>Protesorero</h4>
                            <p>Hugo Osvaldo Belardinelli</p>
                        </li>
                        <li>
                            <h4>Secretario</h4>
                            <p>Carlos Santiago Millán</p>
                        </li>
                        <li>
                            <h4>Prosecretario</h4>
                            <p>Marcelo Ariel Campanella</p>
                        </li>
                        <li>
                            <h4>Consejeros Titulares</h4>
                            <p>
                                Alberto Antonio Baldo <br />
                                Hermes Fiore
                                <br />
                                Hernán Gabriel Amat
                                <br />
                                Ramón Humberto Vivanco
                            </p>
                        </li>
                        <li>
                            <h4>Consejeros Suplentes</h4>
                            <p>
                                Andrés Federico Amaya
                                <br />
                                Roberto Jesús Valot
                                <br />
                                Sergio Rodríguez
                            </p>
                        </li>
                        <li>
                            <h4>Síndico Titular</h4>
                            <p>César Alejandro Páez</p>
                        </li>
                        <li>
                            <h4>Síndico Suplente</h4>
                            <p>Javier Ronco</p>
                        </li>
                    </ul>
                </div>
                <div className='text'>
                    <h2>Misión:</h2>
                    <p>
                        La Cooperativa Eléctrica y Anexos Popular de Rivadavia
                        Limitada es una empresa que a través de sus servicios,
                        trabaja para mejorar la calidad de vida de sus
                        asociados, fundada en la confianza y el trato
                        personalizado; proyectando el futuro en los valores
                        cooperativos.
                    </p>
                </div>
                <div className='text'>
                    <h2> Nuestra Historia</h2>
                    <h3>Historia de la Cooperativa Eléctrica de Rivadavia.</h3>
                    <p>
                        El 22 de mayo de 1911 se firmaba entre la Municipalidad
                        de Rivadavia y los empresarios de la firma “Ronchetti y
                        Galletti”, un contrato por el cual se concedería a la
                        mencionada empresa la licitación para instalar el primer
                        alumbrado público de electricidad en la Villa de
                        Rivadavia. Esto generaría un destacado papel en el
                        progreso rivadaviense.
                    </p>
                    <p>
                        El 9 de julio del mismo año (1911) y durante la
                        Intendencia de Isauro Estrella y la gobernación de
                        Rufino Ortega Ozamis, hijo de Rufino Ortega* (durante su
                        gestión nace Rivadavia en 1884), quedaba definitivamente
                        inaugurada la COOPERATIVA, convirtiendo a Rivadavia en
                        uno de los primeros departamentos del país en contar con
                        alumbrado público en su centro urbano.
                    </p>
                    <p>
                        *Rufino Ortega era un viejo conocedor del departamento
                        de Rivadavia ya que su familia poseía propiedades en la
                        pujante zona de Los Campamentos, lugar que por esos años
                        se conocía precisamente como “Los Campamentos de Ortega”
                    </p>
                    <p>
                        Con el correr de los años, el incremento poblacional y
                        las necesidades crecientes del servicio eléctrico de los
                        habitantes del Departamento, surge “La Usina Eléctrica
                        de Rivadavia”, el 7 de julio de 1947. La misma,
                        posteriormente se llamaría “Cooperativa Eléctrica y
                        Anexos Popular de Rivadavia” (1954) la cual contaría ya
                        desde sus comienzos con un gran número de usuarios (la
                        mayor extensión del territorio).
                    </p>
                    <p>
                        Con el tiempo, dicho servicio público se fue
                        extendiendo, e interpretando el valor del
                        cooperativismo, grupos de vecinos supieron unir
                        capitales particulares y formar una serie de empresas de
                        envergadura para llevar líneas e instalaciones
                        eléctricas a los más apartados rincones del
                        departamento.
                    </p>
                    <p>
                        Al mejorarse en todo el departamento el servicio
                        eléctrico, trajo consigo comodidades y bienestar, y al
                        ampliarse la dotación de energía eléctrica, se
                        instalaron nuevas industrias, se mejoran y modernizaron
                        las existentes, y en suma, todo el departamento
                        emprendió un ritmo de actividades muy alentador.
                    </p>
                </div>
                <div className='text'>
                    <img
                        src='/CoopeAntigua.jpg'
                        alt='USINA ELÉCTRICA RIVADAVIA como SOCIEDAD ANÓNIMA MIXTA'
                    />
                    <h3>Antecedentes de la constitución de la Cooperativa.</h3>
                    <p>
                        <b>La COOPERATIVA</b> fue creada por transformación de
                        la Sociedad Anónima Mixta que explotaba por concesión el
                        servicio eléctrico público, atendiendo además los
                        servicios domiciliarios e industriales de la zona de
                        Rivadavia.
                    </p>
                    <p>
                        En la última Asamblea Extraordinaria que aquella
                        Sociedad realizo el 31 de julio de 1954, dispuso por el
                        voto unánime de sus accionistas, transformarla en
                        Sociedad Cooperativa Limitada, y luego de un cuarto
                        intermedio de cincuenta y cinco días, reanudada la
                        Asamblea, esta resolvió sin oposición, que el nuevo
                        organismo creado, se hiciera cargo del activo y pasivo
                        de la anterior Sociedad Anónima, aprobándose en ese
                        mismo acto los Estatutos Sociales que abrían de regir a
                        la nueva Entidad (Acta Nº 114 - Asamblea Extraordinaria.
                        Se eligió por votación secreta a las personas que
                        integrarían el Primer Consejo de Administración, de la
                        denominada{' '}
                        <b>
                            “Cooperativa Eléctrica Popular de Rivadavia
                            Limitada”
                        </b>
                        .
                    </p>
                    <p>
                        En su primera sesión constitutiva, según consta en el
                        Acta Nº 1 de fecha 1 de mayo de 1955, fueron
                        distribuidos por elección los cargos directivos,
                        resolviéndose desde ese instante, iniciar los trámites
                        legales de inscripción, que fueron total y oportunamente
                        terminados, ante la Dirección Nacional de Cooperativas
                        (hoy I.N.A.E.S. - Instituto Nacional de Actividades y
                        Economicas y Sociales), Superior Gobierno de Mendoza,
                        Dirección Provincial de Cooperativas (hoy Dirección de
                        Asociativismo y Cooperativas) e Inspección de
                        Sociedades.
                    </p>
                    <p>
                        Entre sus primeros dirigentes figuraban Gabriel Setaro,
                        Adolfo Vignoli, Antonio Bastan, Mario Vitale, Jaime
                        Olivella, Rodrigo Galiano, David Boverman, Onofre
                        Matilla, Santiago Cazzoli, Ramón Pérez Fernández,
                        Domingo Catena, Marcial Pérez, Higinio Rodríguez, Manuel
                        Barros, Naser Mansur y Luis Gargantini (h), entre otros.
                    </p>
                    <p>
                        Inscripta en la Dirección de Cooperativas del Ministerio
                        de Comercio de la Nación
                    </p>
                    <p>
                        Personería Jurídica otorgada por el Superior Gobierno de
                        la Provincia: Decreto 1142 - G. Del 30 de marzo de 1955
                    </p>
                    <p>
                        <b>
                            Agradecemos de manera especial al Señor Gonzalo
                            Encina por su aporte en la recopilación de
                            información y material fotográfico para la
                            realización de esta reseña.
                        </b>
                    </p>
                </div>
                <div className='text'>
                    <h2>COOPERATIVISMO</h2>
                    <p>
                        <b>Valores</b>: Las Cooperativas se basan en los valores
                        de la autoayuda, autoresponsabilidad, democracia,
                        igualdad, equidad y solidaridad. En la tradición de sus
                        fundadores, miembros de las cooperativas creen en los
                        valores éticos de honestidad, transparencia,
                        responsabilidad social y preocupación por los demás.
                    </p>
                    <p>
                        <b>Principios</b>: Los principios cooperativos son
                        pautas mediante las cuales las cooperativas ponen en
                        práctica sus valores.
                    </p>
                    <p>
                        <b>1º) Adhesión voluntaria y abierta</b>: Las
                        cooperativas son organizaciones voluntarias, abiertas a
                        todas las personas capaces de utilizar sus servicios y
                        dispuestas a aceptar las responsabilidades de ser socio,
                        sin discriminación de género, la discriminación social,
                        racial, política o religiosa.
                    </p>
                    <p>
                        <b>2º) Control democrático de los miembros</b>: Las
                        cooperativas son organizaciones democráticas controladas
                        por sus miembros, quienes participan activamente en la
                        fijación de sus políticas y la toma de decisiones. Los
                        hombres y mujeres elegidos como representantes son
                        responsables ante los socios. En las cooperativas de
                        base los miembros tienen los mismos derechos de voto (un
                        socio, un voto) y las cooperativas de otros niveles
                        también se organizan de una manera democrática.
                    </p>
                    <p>
                        <b>3º) Participación económica de los miembros</b>: Los
                        miembros contribuyen de manera equitativa y controlan de
                        manera democrática el capital de la cooperativa. Al
                        menos parte de ese capital es propiedad común de la
                        cooperativa. Usualmente reciben una compensación
                        limitada, si los hubiere, sobre el capital entregado
                        como condición para la membresía. Los miembros asignan
                        excedentes para todos o alguno de los siguientes
                        propósitos: el desarrollo de su cooperativa,
                        posiblemente mediante la creación de reservas, de las
                        cuales al menos debe ser indivisible, beneficiando a los
                        socios en proporción a sus operaciones con la
                        cooperativa y el apoyo a otras actividades aprobadas por
                        los socios
                    </p>
                    <p>
                        <b>4º) Autonomía e Independencia</b>: Las cooperativas
                        son organizaciones autónomas de ayuda mutua controladas
                        por sus miembros. Si entran en acuerdos con otras
                        organizaciones, incluyendo gobiernos, o si consiguen
                        capital de fuentes externas, lo hacen en términos que
                        aseguren el control democrático por sus miembros y
                        mantengan la autonomía de la cooperativa
                    </p>
                    <p>
                        <b>5º) Educación, Capacitación e Información</b>: Las
                        cooperativas brindan educación y entrenamiento a sus
                        miembros, los representantes electos, gerentes y
                        empleados, de tal forma que contribuyan eficazmente al
                        desarrollo de sus cooperativas. Las cooperativas
                        informan al público en general – particularmente a
                        jóvenes y líderes de opinión - sobre la naturaleza y los
                        beneficios de la cooperación.
                    </p>
                    <p>
                        <b>6º) Cooperación entre Cooperativas</b>: Las
                        cooperativas sirven a sus miembros más eficazmente y
                        fortalecen el movimiento cooperativo trabajando
                        conjuntamente mediante estructuras locales, nacionales,
                        regionales e internacionales.
                    </p>
                    <p>
                        <b>7º) Compromiso con la comunidad</b>: Las cooperativas
                        trabajan para el desarrollo sostenible de sus
                        comunidades mediante políticas aprobadas por sus socios.
                    </p>
                    <p>
                        <b>Historia</b>: Las cooperativas que comenzó como
                        pequeñas organizaciones de base en Europa Occidental,
                        Norteamérica y Japón a mediados del siglo pasado, sin
                        embargo, se los Pioneros de Rochdale que es considerado
                        como el prototipo de la moderna sociedad cooperativa y
                        los fundadores del movimiento cooperativo. En 1844 un
                        grupo de 28 artesanos que trabajan en las fábricas de
                        algodón en la ciudad de Rochdale, en el norte de
                        Inglaterra estableció la primera cooperativa moderna de
                        negocios, la Sociedad de Pioneros de Rochdale Equitable.
                        Los tejedores se enfrentan las miserables condiciones de
                        trabajo y bajos salarios, y que no podían pagar los
                        altos precios de alimentos y bienes del hogar.
                        Decidieron que, poniendo en común sus escasos recursos y
                        el trabajo conjunto que podían acceder a los bienes
                        básicos a un precio inferior. En un principio, sólo
                        había cuatro artículos a la venta: Harina, la avena, el
                        azúcar y la mantequilla de los pioneros decidió que era
                        tiempo de los compradores fueron tratados con
                        honestidad, apertura y respeto, que debe ser capaz de
                        participar en los beneficios que de costumbre y
                        contribuyó a que deberían tener el derecho democrático
                        de expresar su opinión en el negocio. Cada cliente de la
                        tienda se convirtió en miembro y por lo tanto tenía un
                        interés real en el negocio. Al principio de la
                        cooperativa estaba abierto por sólo dos noches a la
                        semana, pero dentro de tres meses, el negocio había
                        crecido tanto que estaba abierta cinco días a la semana.
                        Los principios en que se basaba su forma de hacer
                        negocios hoy en día todavía son hechos aceptados como
                        las bases en la que todas las cooperativas funcionan.
                        Estos principios han sido revisados ​​y actualizados,
                        pero siguen siendo esencialmente los mismos que los
                        observados en 1844.
                    </p>
                </div>
                <FollowUs />
            </div>
        </div>
    );
};
export default Nosotros;
