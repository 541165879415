import React from 'react';
import BannerTitle from '../componets/bannertitle';
import Layout from '../componets/layout';
import segmentacionImage from '../images/segmentacion1.png';
import segmentacionImageMobile from '../images/segmentacion2.png';
import Segmenenerform from '../componets/segmenenerform';
import Player from 'react-player';

const ResponsabilidadSocial = () => {
    return (
        <div className='contenedor-responsabilidadsocial'>
            <Layout
                title='Cooperativa Eléctrica de Rivadavia - Responsabilidad Social'
                description='Somos la Cooperativa Eléctrica de Rivadavia, dedicada a mejorar la calidad de vida de nuestros asociados a través de servicios eléctricos y valores cooperativos de excelencia.'
                keywords='Cooperativa Eléctrica, Cooperativa Electrica Rivadavia, Cooperativa Electrica Rivadavia Mendoza, CEAPR Rivadavia, Electricidad Rivadavia Mendoza, Electricidad, Asociados CEAPR'
            ></Layout>
            <div className='contenedor-banner'>
                <BannerTitle
                    direccionimagen={`url(${segmentacionImage})`} // Imagen para la versión web
                    direccionimagenMobile={`url(${segmentacionImageMobile})`} // Imagen para la versión móvil
                    titulo={''}
                />
            </div>
            <div className='contenido-secciones'>
                <section className='seccion'>
                    <h2>
                        <b>¿Qué es?</b>
                    </h2>
                    <p>
                        Una iniciativa para ordenar los subsidios a la
                        electricidad y el gas según los aspectos
                        socio-económicos de cada hogar.
                    </p>
                </section>
                <section className='seccion'>
                    <h2>
                        <b>¿Cómo se implementa?</b>
                    </h2>
                    <p>
                        A través de un registro vamos a identificar a los
                        hogares que necesiten el subsidio.
                    </p>
                </section>
                <section className='seccion'>
                    <h2>
                        <b>¿Quiénes deben inscribirse?</b>
                    </h2>
                    <p>
                        Todas y todos los argentinos que quieran mantener los
                        subsidios a la energía que hoy reciben en sus hogares.
                    </p>
                </section>
            </div>
            <div className='requisitos'>
                <h2>
                    <b>Antes de empezar tené a mano:</b>
                </h2>
                <ul>
                    <li>
                        El número de medidor y el número de
                        Cliente/Servicio/Cuenta/Contrato o NIS que están en tu{' '}
                        <br />
                        factura de energía eléctrica y gas natural por red.{' '}
                        <a
                            href='https://www.argentina.gob.ar/subsidios'
                            target='_blank'
                        >
                            Fijate dónde encontrar los números.
                        </a>
                    </li>
                    <li>El último ejemplar de tu DNI.</li>
                    <li>
                        El número de CUIL de cada integrante del hogar mayor de
                        18 años.
                    </li>
                    <li>
                        Los ingresos de bolsillo de cada integrante del hogar
                        mayor de 18 años.
                    </li>
                    <li>Una dirección de correo electrónico.</li>
                    <li>
                        Si en el domicilio de los servicios funciona un comedor
                        o merendero comunitario registrado en el <br /> ReNaCOM,
                        tené a mano el número de registro.
                    </li>
                </ul>
            </div>
            <div className='video-container'>
                <Player
                    url='https://www.youtube.com/watch?v=ceTCYegy7Ok&ab_channel=MinisteriodeEconom%C3%ADa'
                    controls={true}
                    autoplay={false}
                    width={550}
                    height={330}
                />
            </div>
            <div className='form-container'>
                <Segmenenerform />
            </div>

            {/* Estilos */}
            <style jsx>{`
                /* Estilos generales */

                .contenedor-responsabilidadsocial {
                    margin: 0 auto;
                    padding-bottom: 20px;
                    width: 100%;
                }

                .contenedor-banner {
                    display: flex;
                    justify-content: center;
                }

                .contenido-secciones {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    margin-top: 50px;
                }

                .seccion {
                    background-color: white;
                    padding: 20px;
                    margin: 10px;
                    flex: 1 1 300px;
                    max-width: 300px;
                    text-align: center;
                }

                .requisitos {
                    margin-left: 390px;
                    margin-top: 60px;
                    text-align: left;
                }

                .requisitos ul {
                    padding: 0;
                }

                .requisitos ul li {
                    margin-left: 40px;
                    margin-bottom: 10px;
                }

                .requisitos ul li a {
                    color: blue;
                    text-decoration: underline;
                }

                .video-container {
                    display: flex;
                    justify-content: center;
                    margin-top: 50px;
                    margin-bottom: 50px;
                }

                .form-container {
                    width: 100%;
                }

                @media (max-width: 768px) {
                    .contenedor-responsabilidadsocial {
                        margin: 0 auto;
                        padding: 0px;
                        max-width: 100%;
                    }
                    .contenedor-banner {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                    }

                    .seccion {
                        flex: 1 1 100%;
                        max-width: 100%;
                    }

                    .requisitos {
                        margin-left: 20px; /* Ajuste del margen izquierdo */
                        margin-top: 30px; /* Ajuste del margen superior */
                    }

                    .requisitos ul li {
                        margin-left: 20px; /* Ajuste del margen izquierdo */
                        margin-bottom: 10px;
                    }

                    .video-container {
                        margin-top: 30px;
                        margin-bottom: 30px;
                    }

                    .contenido-secciones {
                        margin-top: 0px;
                    }
                    .contenedor-bannertitle {
                        height: 30vh;
                    }
                }
            `}</style>
        </div>
    );
};

export default ResponsabilidadSocial;
