import React from "react";
import { Carousel } from "react-bootstrap";
import banner2 from "../images/2_hp_desk.jpg";
import banner3 from "../images/3_hp_desk.jpg";
import banner1 from "../images/1_hp_desk.jpg";
import banner1mob from "../images/1_hp_mob.jpeg";
import banner2mob from "../images/2_hp_mob.jpeg";
import banner3mob from "../images/3_hp_mob.jpeg";



const CarouselMod =()=>{
return(
    <div>
    <Carousel className="desktop-carousel">
        <Carousel.Item>
          <img className="d-block w-100" src={banner1} alt="First slide" />
          <Carousel.Caption>
            {/*<h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={banner2} alt="Second slide" />

          <Carousel.Caption>
           {/* <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={banner3} alt="Third slide" />

          <Carousel.Caption>
            {/* <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <Carousel className="mobile-carousel">
        <Carousel.Item>
          <img className="d-block w-100" src={banner1mob} alt="First slide" />
          <Carousel.Caption>
           {/* <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={banner2mob} alt="Second slide" />

          <Carousel.Caption>
           {/* <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={banner3mob} alt="Third slide" />

          <Carousel.Caption>
           {/* <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
)
}
export default CarouselMod;