import React, { useState, useEffect } from 'react';
import ImgDeuda from '../images/BTN-DEUDA-remake.png';
import Usuario from '../images/USUARIO.png';
import ImgPagar from '../images/BTN-PAGARremake.png';
import { Link } from 'react-router-dom';
import Redes from './elements/redes';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const EstadoDeCuentaComponent = (props) => {
    const estadoColores = {
        'EN PROCESO': '#5AB507',
        PAGADA: '#5AB507',
        IMPAGA: 'red',
        PARCIAL: 'orange',
    };

    const { facturaData } = props;
    const [expanded, setExpanded] = useState(false);
    const [isMobileView, setIsMobileView] = useState(false);
    const [telefono, setTelefono] = useState(facturaData[0].whatsapp);

    const toggleTable = () => {
        setExpanded(!expanded);
    };

    const checkMobileView = () => {
        const isMobile = window.innerWidth <= 768;
        setIsMobileView(isMobile);
    };

    useEffect(() => {
        checkMobileView();
        window.addEventListener('resize', checkMobileView);
        return () => {
            window.removeEventListener('resize', checkMobileView);
        };
    }, []);

    const greenBoxStyle = {
        background: '#E4EFCD',
        padding: '10px',
        width: '330px',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    const greenTitleStyle = {
        color: '#5AB507',
    };

    const smallerTitleStyle = {
        fontSize: '16px',
    };

    const priceStyle = {
        fontSize: '34px',
        marginTop: '0',
        position: 'relative',
        fontWeight: '400',
        top: '10px',
    };

    const smallTextStyle = {
        fontSize: '10px',
        margin: '0',
        position: 'relative',
        bottom: '10px',
    };

    const usuarioImageStyle = {
        width: '60px',
        marginLeft: 'auto',
        display: 'block',
        marginTop: '-65px',
    };

    const salirLinkStyle = {
        textAlign: 'end',
        marginTop: '5px',
        marginRight: '5px',
        display: 'block',
        color: '#5AB507',
        textDecoration: 'none',
        fontSize: '20px',
    };

    const imageTextContainerStyle = {
        display: 'flex',
        alignItems: 'center',
    };

    const contentContainer = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    const titleStyle = {
        fontSize: '30px',
        fontWeight: 'bold',
        color: '#5AB507',
        marginBottom: '10px',
    };

    const redParagraphStyle = {
        color: 'red',
        marginLeft: '20px',
        marginTop: '8px',
    };

    const tableContainer = {
        width: '90%',
        margin: '10px auto',
        overflowX: 'auto',
        background: 'white',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
        display: isMobileView ? (expanded ? 'block' : 'none') : 'block',
    };

    const tableStyle = {
        width: '100%',
        borderCollapse: 'collapse',
    };

    const thStyle = {
        background: '#E4EFCD',
        padding: '25px',
        textAlign: 'center',
        borderBottom: '1px solid #333',
        fontWeight: 'bold',
    };

    const tdStyle = {
        padding: '8px',
        borderBottom: '1px solid #333',
    };

    const facturasConSaldo = facturaData.filter(
        (factura) => factura.ESTADO === 'IMPAGA' || factura.ESTADO === 'PARCIAL'
    );
    const saldoTotal = facturaData.reduce((total, factura) => {
        const impagaCondition = factura.ESTADO === 'IMPAGA';

        return (
            total +
            (factura.ESTADO === 'PARCIAL'
                ? factura.CTA2_IMP
                : factura.ESTADO === 'EN PROCESO' || factura.ESTADO === 'PAGADA'
                ? 0
                : factura.CTA2_IMP === 0 && impagaCondition
                ? factura.CTA1_IMP
                : factura.SALDO)
        );
    }, 0);

    const handleClickPagar = (e) => {
        e.preventDefault();
        if (saldoTotal === 0) {
            Swal.fire({
                icon: 'success',
                title: '¡Estás al día!',
                text: 'Tu deuda total es $0. No hay facturas pendientes.',
            });
        } else {
            const paymentLink = buildPaymentLink();
            window.location.href = paymentLink;
        }
    };

    const buildPaymentLink = () => {
        const paymentInfo = encodeURIComponent(JSON.stringify(facturaData));
        return `/pagoDeDeuda?facturaData=${paymentInfo}`;
    };

    const handleTelefonoSubmit = async (telefono) => {
        const token =
            '3d5207c8d6225d241825ba21e87e00d0a7dc29f5611de19b8200544b5cea8cd5';
        try {
            const response = await fetch(
                `https://be.cooperativapopular.com.ar/api/deuda?token=${token}&nis=${facturaData[0].NIS}&telefono=${telefono}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            const data = await response.json();
            if (data.error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: data.message,
                });
            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'Teléfono actualizado',
                    text: data.message,
                });
                setTelefono(telefono); // Actualiza el estado del teléfono
            }
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No se pudo actualizar el teléfono.',
            });
        }
    };

    const mostrarEnRojo = facturasConSaldo.length >= 2;
    const redStyles = mostrarEnRojo
        ? {
              color: 'red',
          }
        : {};

    return (
        <div>
            <div className='container' style={{ paddingTop: '50px' }}>
                <div>
                    <h1 style={{ ...greenTitleStyle }}>
                        Estado de cuenta{' '}
                        <a href='/PagaTuFactura'>
                            <img
                                src={Usuario}
                                style={usuarioImageStyle}
                                alt='Usuario'
                            />
                        </a>
                        <a href='/PagaTuFactura' style={salirLinkStyle}>
                            ¡Salir!
                        </a>
                    </h1>
                    <h3 style={smallerTitleStyle}>
                        <b>Número de cuenta: </b>
                        {facturaData[0].NIS}
                    </h3>
                    <h3 style={smallerTitleStyle}>
                        <b>Nombre usuario:</b> {facturaData[0].NOMBRE}
                    </h3>
                    {telefono ? (
                        <h3 style={smallerTitleStyle}>
                            <b>Telefono:</b> {telefono}{' '}
                            <button
                                onClick={() =>
                                    Swal.fire({
                                        title: 'Editar Teléfono',
                                        input: 'text',
                                        inputValue: telefono,
                                        inputAttributes: {
                                            autocapitalize: 'off',
                                        },
                                        showCancelButton: true,
                                        confirmButtonText: 'Guardar',
                                        showLoaderOnConfirm: true,
                                        preConfirm: (telefono) => {
                                            handleTelefonoSubmit(telefono);
                                        },
                                    })
                                }
                            >
                                <i
                                    className='fa fa-pencil'
                                    aria-hidden='true'
                                ></i>
                            </button>
                        </h3>
                    ) : (
                        <h3 style={smallerTitleStyle}>
                            <b>Telefono:</b> Sin informar{' '}
                            <button
                                style={{
                                    marginBottom: '10px',
                                    padding: '4px',
                                    color: 'white',
                                    backgroundColor: '#5AB507',
                                    borderColor: '#5AB507',
                                }}
                                onClick={() =>
                                    Swal.fire({
                                        title: 'Agregar Teléfono',
                                        input: 'text',
                                        inputAttributes: {
                                            autocapitalize: 'off',
                                        },
                                        showCancelButton: true,
                                        confirmButtonText: 'Guardar',
                                        showLoaderOnConfirm: true,
                                        preConfirm: (telefono) => {
                                            handleTelefonoSubmit(telefono);
                                        },
                                    })
                                }
                            >
                                Agregar
                            </button>
                        </h3>
                    )}
                </div>
                <div style={{ ...greenBoxStyle, ...redStyles }}>
                    <p style={priceStyle}>$ {saldoTotal}</p>
                    <p style={smallTextStyle}>DEUDA TOTAL</p>
                </div>
                <div>
                    <p style={{ marginTop: '40px' }}>
                        Hace clic en{' '}
                        <img src={ImgPagar} style={{ width: '40px' }} /> PAGAR,
                        para pagar tus facturas adeudadas.
                    </p>
                    <div style={{ padding: '0px 0', marginBottom: '15px' }}>
                        <Link
                            to={buildPaymentLink()}
                            style={{ textDecoration: 'none' }}
                            onClick={handleClickPagar}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={ImgPagar}
                                    alt='Pagar'
                                    style={{
                                        width: '50px',
                                        marginRight: '10px',
                                    }}
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        position: 'relative',
                                        top: '6px',
                                        fontWeight: '500',
                                        color: '#5AB507',
                                    }}
                                >
                                    <p>PAGAR</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div style={contentContainer}>
                <h3 style={{ ...titleStyle, ...redStyles }}>Tus Facturas</h3>
                <p style={redParagraphStyle}>
                    Muestra tus últimas facturas y sus saldos.
                </p>
                {isMobileView && (
                    <button
                        onClick={toggleTable}
                        style={{
                            marginBottom: '10px',
                            backgroundColor: '#5AB507',
                            borderColor: '#5AB507',
                            fontWeight: 'bold',
                            display: 'block',
                        }}
                        className='btn btn-primary'
                    >
                        {expanded ? 'Ocultar Facturas' : 'Mostrar Facturas'}
                    </button>
                )}
            </div>
            <div style={{ ...tableContainer }}>
                <table style={tableStyle}>
                    <thead>
                        <tr className='text-center'>
                            <th scope='col'>NIS</th>
                            <th scope='col'>Nro. Fact.</th>
                            <th scope='col'>1º Venc.</th>
                            <th scope='col'>Imp. 1</th>
                            <th scope='col'>2º Venc.</th>
                            <th scope='col'>Imp. 2</th>
                            <th scope='col'>Total</th>
                            <th scope='col'>Saldo</th>
                            <th scope='col'>Estado</th>
                        </tr>
                    </thead>
                    <tbody>
                        {facturaData.map((factura, index) => (
                            <tr key={index} className='text-center'>
                                <td style={tdStyle}>{factura.NIS}</td>
                                <td style={tdStyle}>{factura.NROFACT}</td>
                                <td style={tdStyle}>{factura.CTA1_VTO}</td>
                                <td style={tdStyle}>${factura.CTA1_IMP}</td>
                                <td style={tdStyle}>{factura.CTA2_VTO}</td>
                                <td style={tdStyle}>${factura.CTA2_IMP}</td>
                                <td style={tdStyle}>${factura.TOTAL}</td>
                                <td style={tdStyle}>
                                    {factura.ESTADO === 'PARCIAL'
                                        ? factura.CTA2_IMP
                                        : factura.ESTADO === 'EN PROCESO' ||
                                          factura.ESTADO === 'PAGADA'
                                        ? '0'
                                        : factura.CTA2_IMP === 0 &&
                                          factura.ESTADO === 'IMPAGA'
                                        ? factura.CTA1_IMP
                                        : factura.SALDO}
                                </td>
                                <td
                                    style={{
                                        ...tdStyle,
                                        color: estadoColores[factura.ESTADO],
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {factura.ESTADO}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Redes />
        </div>
    );
};

export default EstadoDeCuentaComponent;
