import React from 'react';
import contacto1 from '../images/contacto1.png';
import contacto2 from '../images/contacto2.png';
import contacto3 from '../images/carrito.png';
import contact4 from '../images/contacto5.png';
import contact5 from '../images/contacto6.png';
const ContactoMod = () => {
    return (
        <div className='contenedor-contacto'>
            <div className='contenedor-info'>
                <h1>Contactanos:</h1>
                <ul>
                    <li>
                        <img src={contacto1} alt='' />
                        <div className='contacto-info-item'>
                            <a
                                href='https://wa.me/5492612380051'
                                target='_blank'
                            >
                                <h3>2612380051</h3>
                            </a>
                            <p>Guardia de emergencia eléctrica</p>
                        </div>
                    </li>
                    <li>
                        <img src={contacto3} alt='' />
                        <div className='contacto-info-item'>
                            <a
                                href='https://wa.me/5492612396101'
                                target='_blank'
                            >
                                <h3>2612396101</h3>
                            </a>
                            <p>Salon de ventas</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div className='contenedor-img-chat'>
                <a href='/'>
                    <img src={contact4} alt={contact4} />
                    <img src={contact5} alt={contact5} />
                </a>
            </div>
            <style>
                {`
    @media (max-width: 767px) {
        .contenedor-contacto {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        .contenedor-info, .contenedor-img-chat {
            width: 100%;
            text-align: center;
        }
        li {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .contacto-info-item {
            margin-left: auto;
        }
        img {
            width: 50%;
            height: auto;
            object-fit: contain;
            margin-right: auto;
        }
        .contenedor-img-chat {
            display: none;
        }
    }    
    `}
            </style>
        </div>
    );
};
export default ContactoMod;
