import React, { useState } from 'react';
import axios from 'axios';
import icono1 from '../images/contacto11.png';
import icono2 from '../images/contacto12.png';
import icono3 from '../images/contacto13.png';

const ContactForm = () => {
    const [nombre, setNombre] = useState('');
    const [correo, setCorreo] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [status, setStatus] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post(
                'https://be.cooperativapopular.com.ar/api/contacto',
                {
                    nombre: nombre,
                    correo: correo,
                    mensaje: mensaje,
                }
            );
            setStatus('success');
            setNombre('');
            setCorreo('');
            setMensaje('');
        } catch (error) {
            setStatus('error');
        }
    };

    return (
        <div className='contact-form'>
            <p className='optional-text'>
                También podés rellenar el formulario ó enviarnos un correo a
                <a href='mailto=comercial@cooperativapopular.com.ar'>
                    {' '}
                    comercial@cooperativapopular.com.ar
                </a>
            </p>
            {status === 'success' && <p>Mensaje enviado exitosamente</p>}
            {status === 'error' && <p>Ocurrió un error al enviar el mensaje</p>}
            <div className='background-form'>
                <form onSubmit={handleSubmit}>
                    <div className='input-item'>
                        <img src={icono1} alt='' />
                        <label>
                            Nombre:
                            <input
                                type='text'
                                value={nombre}
                                onChange={(e) => setNombre(e.target.value)}
                            />
                        </label>
                    </div>
                    <div className='input-item'>
                        <img src={icono2} alt='' />
                        <label>
                            Correo:
                            <input
                                type='email'
                                value={correo}
                                onChange={(e) => setCorreo(e.target.value)}
                            />
                        </label>
                    </div>
                    <div className='input-item'>
                        <img src={icono3} alt='' />
                        <label className='label-ta'>
                            Mensaje:
                            <textarea
                                value={mensaje}
                                onChange={(e) => setMensaje(e.target.value)}
                            />
                        </label>
                    </div>
                    <div className='input-item'>
                        <button type='submit'>Enviar</button>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default ContactForm;
