import React from 'react';
import Banner from '../componets/banner';
import Layout from '../componets/layout';
import Redes from '../componets/elements/redes';
import banner1 from '../images/banner1.png';
import PaymentMethod from '../componets/paymentmethod';
import BuscadorFactura from '../componets/buscadorfactura';

const DescargarFactura = () => {
    return (
        <div>
            <Layout
                title='Cooperativa Eléctrica de Rivadavia - Descargar Factura'
                description='En la Cooperativa Eléctrica de Rivadavia, estamos comprometidos con servir a la comunidad al proporcionar servicios eléctricos de calidad y mantener los principios cooperativos.'
                keywords='Cooperativa Eléctrica, Cooperativa Electrica Rivadavia, Cooperativa Electrica Rivadavia Mendoza, CEAPR Rivadavia'
            ></Layout>
            <Banner img={banner1} textoVisible={'visible'} />
            <BuscadorFactura />
            <PaymentMethod />
            <Redes />
        </div>
    );
};

export default DescargarFactura;
