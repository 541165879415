import React, { useState, useEffect } from 'react';
import Banner from '../componets/banner';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Layout from '../componets/layout';

const BloqueTexto = ({ titulo, subtitulo, texto, alineacion }) => {
    const [editorText, setEditorText] = useState('');

    useEffect(() => {
        setEditorText(texto);
    }, [texto]);

    const modules = {
        toolbar: false,
    };

    return (
        <div className={`bloque-texto bloque-texto-${alineacion}`} style={{}}>
            <Layout
                title='Cooperativa Eléctrica de Rivadavia - Novedades'
                description='En la Cooperativa Eléctrica de Rivadavia, nuestros valores cooperativos se traducen en un servicio eléctrico confiable y un compromiso inquebrantable con la calidad de vida de nuestros asociados.'
                keywords='Cooperativa Eléctrica, Cooperativa Electrica Rivadavia, Cooperativa Electrica Rivadavia Mendoza, CEAPR Rivadavia, Electricidad Rivadavia Mendoza, Electricidad, Asociados CEAPR'
            ></Layout>
            <h2>{titulo}</h2>
            <br />
            <h5>{subtitulo}</h5>
            <br />
            <ReactQuill
                className='mi-quill'
                modules={modules}
                value={editorText}
                readOnly
            />
        </div>
    );
};

const Novedades = () => {
    const [loading, setLoading] = useState(false);
    const [novedades, setNovedades] = useState([]);

    useEffect(() => {
        const cargarNovedades = async () => {
            setLoading(true);
            const response = await axios.get(
                'https://be.cooperativapopular.com.ar/api/novedades'
            );
            setNovedades(response.data);
            setLoading(false);
        };
        cargarNovedades();
    }, []);

    return (
        <div className='contenedor contenedor-novedad'>
            {loading ? (
                <p>Cargando...</p>
            ) : (
                <>
                    {novedades.map((item) => (
                        <>
                            <Banner img={item.imagen} textoVisible='none' />
                            <BloqueTexto
                                alineacion='left'
                                titulo={item.titulo}
                                subtitulo={item.subtitulo}
                                texto={JSON.parse(item.cuerpo)}
                            />
                            <hr />
                        </>
                    ))}
                </>
            )}
        </div>
    );
    <style>
        {`
    .img {
      width: 80%;
    }
  `}
    </style>;
};
export default Novedades;
