import React, { useRef } from 'react';

const DownloadFileButton = () => {
    // Crear referencia al enlace
    const linkRef = useRef();

    // Función para descargar archivo
    const downloadFile = () => {
        // Simular clic en enlace
        linkRef.current.click();
    };

    return (
        <>
            <a
                href='https://epremendoza.gob.ar/normas-provinciales/'
                ref={linkRef}
                target='_blank'
            >
                Descargas EPRE
            </a>
            <br />
            <a
                href='https://epremendoza.gob.ar/reglamento-de-suministro/'
                ref={linkRef}
                target='_blank'
            >
                REGLAMENTO DE SUMINISTRO
            </a>
            {/* Enlace oculto para iniciar descarga del archivo */}
            {/* 
            <a
                href='https://epremendoza.gob.ar/normas-provinciales/'
                ref={linkRef}
                style={{ display: 'none' }}
            >
                Ir a descargas
            </a> */}

            {/* Botón para descargar archivo */}
            {/* <button className='btn btn-outline-primary'>
                Descargar archivo
            </button> */}
        </>
    );
};

export default DownloadFileButton;
