import React from 'react';
import AccordionElement from '../componets/elements/accordionelement';
import Layout from '../componets/layout';

const PreguntasFrecuentes = () => {
    return (
        <div className='contenedor-faq'>
            <Layout
                title='Cooperativa Eléctrica de Rivadavia - Preguntas Frecuentes'
                description='La Cooperativa Eléctrica de Rivadavia: iluminando hogares, impulsando el progreso, y construyendo un futuro cooperativo para nuestra comunidad desde 1911.'
                keywords='Cooperativa Eléctrica, Cooperativa Electrica Rivadavia, Cooperativa Electrica Rivadavia Mendoza, CEAPR Rivadavia, Electricidad Rivadavia Mendoza, Electricidad, Asociados CEAPR'
            ></Layout>
            <div className='contenedor-titlefaq'>
                <h1>Servicio al cliente</h1>
                <h2>Preguntas Frecuentes</h2>
            </div>
            <AccordionElement />
        </div>
    );
};
export default PreguntasFrecuentes;
