import React from 'react';
import BannerTitle from '../componets/bannertitle';
import { useNavigate } from 'react-router-dom';
import ContactForm from '../componets/contacto-form';
import bannerMateriales from '../images/unnamed.png';
import oferta1 from '../images/oferta1.jpeg';
import oferta2 from '../images/oferta2.jpeg';
import oferta3 from '../images/oferta3.jpeg';
import oferta4 from '../images/oferta4.jpeg';
import oferta5 from '../images/oferta5.jpeg';
import oferta6 from '../images/oferta6.jpeg';
import Layout from '../componets/layout';
const Venta = () => {
    let navigate = useNavigate();
    return (
        <div className='contenedor-venta'>
            <Layout
                title='Cooperativa Eléctrica de Rivadavia - Venta'
                description='Somos la Cooperativa Eléctrica de Rivadavia, dedicada a mejorar la calidad de vida de nuestros asociados a través de servicios eléctricos y valores cooperativos de excelencia.'
                keywords='Cooperativa Eléctrica, Cooperativa Electrica Rivadavia, Cooperativa Electrica Rivadavia Mendoza, CEAPR Rivadavia, Electricidad Rivadavia Mendoza, Electricidad, Asociados CEAPR'
            ></Layout>
            <BannerTitle
                titulo='Venta de Materiales'
                direccionimagen='url(/banner2.png)'
            />
            <h2 className='mt-3'>MATERIALES DISPONIBLES</h2>
            <div className='venta-content'>
                <div className='venta-items'>
                    <h3 className='mb-5'>
                        VENTA DE MATERIALES / SALON DE VENTAS
                    </h3>
                    <div
                        className='items-content'
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <div className='grid-item'>
                            <img
                                src={bannerMateriales}
                                style={{ width: 'auto', maxWidth: '100%' }}
                                alt='oferta'
                            ></img>
                        </div>
                    </div>
                </div>
                <div className='cotizar'>
                    <h3>COTIZAMOS TU PEDIDO</h3>
                    <p>
                        Complete el siguiente formulario con sus datos, producto
                        y cantidad que desea, le enviaremos la cotización a su
                        correo
                    </p>
                    <ContactForm />
                </div>
            </div>
            <style>
                {`
    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
        align-items:center;
        justify-content:center;
    }
    .grid-item {
        text-align: center;
        align-self: center;
    }
    .img-center {
        max-width: 100%;
        max-height: auto;
        width: 100%;
        height: auto;
        object-fit: contain;
    }
    h2, h3 {
        font-size:2em;
        text-align: center;
        width: 100%;
    }
    @media (max-width: 767px) {
      /* Estilos para dispositivos móviles con ancho de pantalla hasta 767px */
      .contenedor-venta {
      width:100%;
      text-align:center;
      }
      .img-center {
      max-width:100%;
      width:100%;
      height:auto;
      object-fit:contain;
      }
      .venta-content {
      width:100%;
      flex-wrap:wrap;
      flex-direction:column;
      align-items:center;
      justify-content:center;
      }
      .venta-item {
      text-align:center;
      }
      h2, h3 {
        font-size:1.5em;
      text-align: center;
      width: 100%;
      }
      .grid-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
      width:100%;
      align-items:center;
      justify-content:center;
      }
      .grid-item {
      text-align: center;
      }
      .cotizar {
      width:100%;
      text-align:center;
      }
      }
      `}
            </style>
        </div>
    );
};
export default Venta;
