import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import lamparaoff from "../images/lampara-off.png";
import lamparaon from "../images/lampara-on.png";
import lampbtn from "../images/lamp-btn.png";
import esquinaimg from "../images/esquina.png";
import { Nav } from "react-bootstrap";

const EficienciaEnergetica = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [lamparaImg, setLamparaImg] = useState(lamparaoff);

  const handleMouseEnter = () => {
    setLamparaImg(lamparaon);
  };

  const handleMouseLeave = () => {
    setLamparaImg(lamparaoff);
  };

  return (
    <div id="eficiencia-energetica" class="eficiencia-energetica" ref={ref}>
      <div class="div-2-eficiencia">
        <div class="titulo">
          <h1>
            <b>EFICIENCIA</b> ENERGÉTICA
          </h1>
          <Nav.Link class="link" href="/eficienciaEnergetica">
            <img class="btn-eficiencia" src={lampbtn} alt="logo-coope-tw" />
          </Nav.Link>
        </div>
        <img
          src={lamparaImg}
          alt="lampara"
          className="lampara lampara-eficiencia"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        <img
          className="esquina-img-lamp esquina-img-eficiencia"
          src={esquinaimg}
          alt="fondo-artistico"
        />
      </div>
    </div>
  );
};

export default EficienciaEnergetica;
