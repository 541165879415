import React from 'react';

//TextoVisible = flex/none/
//img = url de la imagen
// este banner contiene un TEXTO DEBAJO
const Banner = (promp) => {
    return (
        <div className='banner'>
            <img
                src={promp.img}
                alt='banner'
                style={{ width: '98%', margin: ' 0 auto' }}
            />
            <div
                className='banner-text'
                style={{ display: promp.textoVisible }}
            >
                <h4>DESCARGÁ TU FACTURA</h4>
            </div>
        </div>
    );
};
export default Banner;
