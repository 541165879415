import React from 'react';
import btnform from '../images/btn-form.png';

const PaymentMethod = () => {
    return (
        <div className='btn-form-container'>
            <div className='btn-form'>
                <img src={btnform} alt='banco1' className='btn-form-image' />
                <h1 className='text-btn-form'>
                    <b>Ingresá en </b>
                    <a
                        href='https://www.argentina.gob.ar/subsidios'
                        style={{ textDecoration: 'none', color: 'green' }}
                    >
                        www.argentina.gob.ar/subsidios
                    </a>{' '}
                    <br />y completá tus datos!
                </h1>
            </div>
            <div className='office-info'>
                <h4 className='text-btn-form-h4'>
                    Acercate a nuestras oficinas si necesitas recibir
                    asesoramiento personalizado!
                </h4>
            </div>
            <style jsx>{`
                .btn-form-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background-color: #d9dadf;
                    padding: 20px;
                }

                .btn-form {
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                }

                .btn-form-image {
                    width: 13%;
                    margin: 50px;
                    align-item: center;
                }

                .text-btn-form {
                    text-align: center;
                }

                @media (max-width: 768px) {
                    /* Estilos específicos para dispositivos móviles */
                    .btn-form {
                        flex-direction: column;
                        align-items: center;
                    }
                    .text-btn-form {
                        font-size: 20px;
                        text-align: center;
                        margin-top: 20px;
                    }
                    .text-btn-form-h4 {
                        font-size: 18px;
                        text-align: center;
                    }
                }
            `}</style>
        </div>
    );
};

export default PaymentMethod;
