import React from 'react';
import subsidio from '../images/subsidio.png';
import Layout from '../componets/layout';
import Redes from '../componets/elements/redes';

const Subsidios = () => {
    // Verificar si la ventana tiene un ancho menor a 768px (puedes ajustar este valor según tus necesidades).
    const isMobile = window.innerWidth < 768;

    return (
        <div
            style={{
                background: '#ffffff',
                color: 'black',
                fontWeight: 'bold',
                fontSize: '25px',
                textAlign: 'center',
                width: '100%',
                height: '100%',
                margin: 'auto',
            }}
        >
            <h1 style={{ color: '#00c853', fontWeight: 'bold' }}>
                Adherite a nuestro Subsidio por Fallecimiento
            </h1>
            <ul>
                <li style={{ listStyle: 'none' }}>
                    <span>
                        <p>Beneficiarios: Titular y grupo familiar primario.</p>{' '}
                    </span>
                </li>
                <li style={{ listStyle: 'none' }}>
                    <p>Adhesión inmediata</p>
                </li>
                <li style={{ listStyle: 'none' }}>
                    <p>Liquidación autogestionada por la Cooperativa</p>
                </li>
                <li style={{ listStyle: 'none' }}>
                    <p>Adherite a nuestro Subsidio por Fallecimiento</p>
                </li>
            </ul>
            <p>COOPERATIVA ELÉCTRICA Y ANEXOS POPULAR DE RIVADAVIA LTDA.</p>
            <p style={{ color: '#00c853', fontWeight: 'bold' }}>
                COMUNICATE CON NOSOTROS
            </p>
            <p style={{ color: '#00c853', fontWeight: 'bold' }}>261 2380051</p>
            <Redes />
        </div>
    );
};

export default Subsidios;
