import React from 'react';
import { Nav } from 'react-bootstrap';
import redesig from '../../images/coope-ig-bl.png';
import redesface from '../../images/coope-faceb-bl.png';
import redestw from '../../images/coope-tw-bl.png';
import redeswp from '../../images/coope-waap-bl.png';
const Redes = () => {
    return (
        <div className=' redes-efiener'>
            <Nav.Item>
                <Nav.Link
                    href='https://www.facebook.com/CEAPRltda'
                    target='_blank'
                >
                    <img
                        className='logo-coope-home'
                        src={redesface}
                        alt='logo-coope-face'
                    />
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href='https://wa.me/5492612380051' target='_blank'>
                    <img
                        className='logo-coope-home'
                        src={redeswp}
                        alt='logo-coope-ig'
                    />
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href='https://twitter.com/CEAPRltda' target='_blank'>
                    <img
                        className='logo-coope-home'
                        src={redestw}
                        alt='logo-coope-tw'
                    />
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link
                    href='https://www.instagram.com/ceaprltda/'
                    target='_blank'
                >
                    <img
                        className='logo-coope-home'
                        src={redesig}
                        alt='logo-coope-ig'
                    />
                </Nav.Link>
            </Nav.Item>
        </div>
    );
};
export default Redes;
