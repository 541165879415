import React from 'react';

import Redes from '../componets/elements/redes';
import ContactoMod from '../componets/contact';
import ContactForm from '../componets/contacto-form';
import Layout from '../componets/layout';

const Contacto = () => {
    return (
        <div>
            <Layout
                title='Cooperativa Eléctrica de Rivadavia - Contacto'
                description='La Cooperativa Eléctrica y Anexos Popular de Rivadavia Limitada es una empresa que trabaja para mejorar la calidad de vida de sus asociados a través de servicios eléctricos y valores cooperativos.'
                keywords='Cooperativa Eléctrica, Cooperativa Electrica Rivadavia, Cooperativa Electrica Rivadavia Mendoza, CEAPR Rivadavia'
            ></Layout>
            <ContactoMod />
            <ContactForm />
            <Redes />
        </div>
    );
};

export default Contacto;
