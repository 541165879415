import React from 'react';

import PaymentMethod from '../componets/paymentmethod';
import Layout from '../componets/layout';

const MedioDePago = () => {
    return (
        <div>
            <Layout
                title='Cooperativa Eléctrica de Rivadavia - Medios De Pago'
                description='La Cooperativa Eléctrica y Anexos Popular de Rivadavia Limitada es una empresa que trabaja para mejorar la calidad de vida de sus asociados a través de servicios eléctricos y valores cooperativos.'
                keywords='Cooperativa Eléctrica, Cooperativa Electrica Rivadavia, Cooperativa Electrica Rivadavia Mendoza, CEAPR Rivadavia, Electricidad Rivadavia Mendoza'
            ></Layout>
            <PaymentMethod />
        </div>
    );
};
export default MedioDePago;
