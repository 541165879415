import React from 'react';

import Historia from '../componets/Historia';
import Redes from '../componets/elements/redes';
import Banner from '../componets/banner';
import Banner1 from '../images/banner1.png';
import Layout from '../componets/layout';

const LaCooperativa = () => {
    return (
        <div>
            <Layout
                title='Cooperativa Eléctrica de Rivadavia - Cooperativa'
                description='La Cooperativa Eléctrica y Anexos Popular de Rivadavia Limitada es una empresa que trabaja para mejorar la calidad de vida de sus asociados a través de servicios eléctricos y valores cooperativos.'
                keywords='Cooperativa Eléctrica, Cooperativa Electrica Rivadavia, Cooperativa Electrica Rivadavia Mendoza, CEAPR Rivadavia'
            ></Layout>
            <Banner img={Banner1} textoVisible={'none'} />
            <Historia />

            <Redes />
        </div>
    );
};

export default LaCooperativa;
