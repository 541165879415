import React from 'react';
import EjemploFact from '../images/EEMPLO-FACTURA.png';

const BuscadorFactura = () => {
    const handleSubmit = (event) => {
        event.preventDefault();

        // Obtener los valores de los campos de socio y suministro
        const socio = document.getElementById('socio').value;
        const suministro = document.getElementById('suministro').value;

        // Construir la nueva URL con los valores de socio y suministro
        const nuevaUrl = `https://facturas.cooperativapopular.com.ar/movil/pdfconsulta2.php?socio=${socio}&suministro=${suministro}`;

        // Actualizar la acción del formulario con la nueva URL
        document.getElementById('miFormulario').action = nuevaUrl;

        // Resto del código para abrir la ventana de impresión
        window.open('about:blank', 'print_popup', 'width=600,height=400');
        document.getElementById('miFormulario').submit();
    };

    return (
        <section className='contenedor-buscadorfactura'>
            <div className='contenedor-itembuscador'>
                <h2>Datos Necesarios</h2>
                <div className='factura-img'>
                    <img src={EjemploFact} alt='' />
                </div>
            </div>
            <div className='contenedor-itembuscador'>
                <h2>Descargar Facturas</h2>
                <form
                    id='miFormulario'
                    action='https://facturas.cooperativapopular.com.ar/movil/pdfconsulta2.php'
                    method='post'
                    target='print_popup'
                    onSubmit={(e) => handleSubmit(e)}
                >
                    <div className='item'>
                        <label>
                            Número de socio
                            <input
                                placeholder='ej: 0000424'
                                type='number'
                                id='socio'
                                name='socio'
                            />
                        </label>
                    </div>
                    <div className='item'>
                        <label>
                            Número de suministro
                            <input
                                id='suministro'
                                name='suministro'
                                placeholder='Número de Suministro'
                            />
                        </label>
                    </div>
                    <button type='submit'>Buscar</button>
                    <input name='action' type='hidden' value='imprimir' />
                </form>
                <style>
                    {`
          @media only screen and (max-width: 767px) {
            .contenedor-buscadorfactura {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
          }
          .contenedor-itembuscador {
              width: 100%;
              text-align: center;
          }
          h2 {
              width: 100%;
              text-align: center;
              margin-top: 20px;
          }
          .factura-img img {
              width: 100%;
              max-width: 300px;
              height: auto;
              margin: auto;
              object-fit: contain;
          }
          form {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
          }
          .item {
              width: 100%;
              text-align: center;
          }
          input[type="number"], input[type="text"] {
              width: 100%;
              margin-bottom: 10px;
          }
          button[type="submit"] {
              width: 100%;
              margin-top: 10px;
          }

          }
          `}
                </style>
            </div>
        </section>
    );
};
export default BuscadorFactura;
