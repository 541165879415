import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import atencion from '../images/atencion.png';

const Popup = () => {
    useEffect(() => {
        Swal.fire({
            title: '',
            html: `
                 <div style="max-width: 100%; height: auto;">
                    <img src=${atencion} alt="Descripción de la imagen" style="max-width: 100%; height: auto;" />
                </div>
            `,
            //showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),

            /* preConfirm: () => {
                window.open(
                    'https://epremendoza.gob.ar/tarifa-social/',
                    '_blank'
                );
            }, */
            //timer: 15000,
            //timerProgressBar: true,
        });
    }, []);

    return null;
};

export default Popup;

{
    /* <p>
    <b>Consulte si Ud. es Beneficiario de la TARIFA SOCIAL.</b> <br /> Ingresa a{' '}
    <a
        href='https://epremendoza.gob.ar/tarifa-social/'
        target='_blank'
        rel='noopener noreferrer'
    >
        https://epremendoza.gob.ar/tarifa-social/
    </a>{' '}
    y verifica si estás inscripto en la tarifa social.
</p>; */
}
