import React from 'react';
import AccordionElement from '../componets/elements/accordionelement';
import Layout from '../componets/layout';

const ServicioCliente = () => {
    return (
        <div className='contenedor-faq'>
            <Layout
                title='Cooperativa Eléctrica de Rivadavia - Servicio al Cliente'
                description='Somos la Cooperativa Eléctrica de Rivadavia, dedicada a mejorar la calidad de vida de nuestros asociados a través de servicios eléctricos y valores cooperativos de excelencia.'
                keywords='Cooperativa Eléctrica, Cooperativa Electrica Rivadavia, Cooperativa Electrica Rivadavia Mendoza, CEAPR Rivadavia, Electricidad Rivadavia Mendoza, Electricidad, Asociados CEAPR'
            ></Layout>
            <div className='contenedor-titlefaq'>
                <h1>Servicio al cliente</h1>
                <h2>Preguntas Frecuentes</h2>
                <hr />
            </div>
            <AccordionElement />
        </div>
    );
};

export default ServicioCliente;
