import React from 'react';
import TitleGeneric from '../componets/titulogen';
import Layout from '../componets/layout';

const Concesion = () => {
    return (
        <div>
            <Layout
                title='Cooperativa Eléctrica de Rivadavia - Concesion'
                description='Somos la Cooperativa Eléctrica de Rivadavia, dedicada a mejorar la calidad de vida de nuestros asociados a través de servicios eléctricos y valores cooperativos de excelencia.'
                keywords='Cooperativa Eléctrica, Cooperativa Electrica Rivadavia, Cooperativa Electrica Rivadavia Mendoza, CEAPR Rivadavia'
            ></Layout>
            <TitleGeneric text='concesion' />
        </div>
    );
};
export default Concesion;
