import React from 'react';
import CarouselMod from './carousel';
import EficienciaEnergetica from './eficienciaEnergetica';
import SpinnerMod from './spinner';
import NovedadesTitle from './novedades';
import NovedadesCards from './novedadesCard';
import Redes from './elements/redes';

const BodyHP = () => {
    return (
        <div className='maincontent-home'>
            <CarouselMod />
            <SpinnerMod />
            <h1
                className='titulo'
                style={{
                    marginTop: '100px',
                    textAlign: 'center',
                    color: '#00c853',
                }}
            >
                <b>EFICIENCIA ENERGETICA</b>
            </h1>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '20px 0',
                }}
            >
                <video
                    controls
                    style={{
                        maxWidth: '70%',
                        height: 'auto',
                        border: '2px solid #ccc',
                        borderRadius: '10px',
                        marginTop: '10px',
                    }}
                >
                    <source src='/cooperativa.mp4' type='video/mp4' />
                    Your browser does not support the video tag.
                </video>
            </div>

            {/* <NovedadesTitle title="NOVEDADES" /> */}
            <NovedadesCards />

            <br />
            <br />
            <br />
            <br />

            <EficienciaEnergetica />
            <Redes />
        </div>
    );
};

export default BodyHP;
