import React from 'react';
import Layout from '../componets/layout';

const NotFoundPage = () => {
    return (
        <div>
            <Layout
                title='Cooperativa Eléctrica de Rivadavia - Pago Fallido'
                description='Descubre cómo la Cooperativa Eléctrica de Rivadavia ha sido un faro de excelencia en el suministro de electricidad y en la promoción de los valores cooperativos en Rivadavia durante más de un siglo.'
                keywords='Cooperativa Eléctrica, Cooperativa Electrica Rivadavia, Cooperativa Electrica Rivadavia Mendoza, CEAPR Rivadavia, Electricidad Rivadavia Mendoza, Electricidad, Asociados CEAPR'
            ></Layout>
            <div
                style={{
                    width: '100%',
                    height: '50vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        display: 'block',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                    }}
                >
                    <h1
                        style={{
                            fontWeight: 'bold',
                            color: '#FF8000',
                            textAlign: 'center',
                        }}
                    >
                        PAGO PENDIENTE
                    </h1>
                    <p>
                        Tú pago esta pendiente, espera unos instantes hasta que
                        se procese, <br /> de lo contrario comunicate con
                        MercadoPago.
                    </p>
                    <a
                        href='/'
                        style={{
                            backgroundColor: 'initial',
                            backgroundImage:
                                'linear-gradient(-180deg, #FF8000, #FF8000)',
                            borderRadius: '5px',
                            boxShadow: 'rgba(0,0,0,0.1) 0 2px 4px',
                            color: '#FFFFFF',
                            cursor: 'pointer',
                            display: 'inline-block',
                            height: '44px',
                            lineHeight: '44px',
                            outline: '0',
                            overflow: 'hidden',
                            padding: '0 20px',
                            textDecoration: 'none',
                            fontWeight: 'bold',
                        }}
                    >
                        Volver
                    </a>
                </div>
            </div>
        </div>
    );
};
export default NotFoundPage;
