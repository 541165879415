import React from 'react';
import Layout from '../componets/layout';

const NotFoundPage = () => {
    return (
        <div>
            <Layout
                title='Cooperativa Eléctrica de Rivadavia - Pagina No Encontrada'
                description='Descubre cómo la Cooperativa Eléctrica de Rivadavia ha sido un faro de excelencia en el suministro de electricidad y en la promoción de los valores cooperativos en Rivadavia durante más de un siglo.'
                keywords='Cooperativa Eléctrica, Cooperativa Electrica Rivadavia, Cooperativa Electrica Rivadavia Mendoza, CEAPR Rivadavia, Electricidad Rivadavia Mendoza, Electricidad, Asociados CEAPR'
            ></Layout>
            <h1>PAGINA NO ENCONTRADA</h1>
        </div>
    );
};
export default NotFoundPage;
